import DefaultTheme from '@mindful-web/marko-web-theme-default/browser';
import FII from '@mindful-web/marko-web-p1-fii/browser';
import GTM from '@mindful-web/marko-web-gtm/browser';
import GAM from '@mindful-web/marko-web-gam/browser';
import Inquiry from '@mindful-web/marko-web-inquiry/browser';
import P1Events from '@mindful-web/marko-web-p1-events/browser';
import Leaders from '@mindful-web/marko-web-leaders/browser';
import RevealAd from '@mindful-web/marko-web-reveal-ad/browser';
import Search from '@mindful-web/marko-web-search/browser';
import SocialSharing from '@mindful-web/marko-web-social-sharing/browser';
import PhotoSwipe from '@mindful-web/marko-web-photoswipe/browser';
import OmedaIdentityX from '@mindful-web/marko-web-omeda-identity-x/browser';
import ContactUs from '@mindful-web/marko-web-contact-us/browser';

const AutoScroll = () => import(/* webpackChunkName: "shared-auto-scroll" */ './auto-scroll.vue');
const MenuToggleButton = () => import(/* webpackChunkName: "shared-menu-toggle-button" */ './menu-toggle-button.vue');
const WufooForm = () => import(/* webpackChunkName: "wufoo-form" */ './wufoo-form.vue');
const SurveyGizmoForm = () => import(/* webpackChunkName: "survey-gizmo-form" */ './survey-gizmo-form.vue');
const Figure = () => import(/* webpackChunkName: "figure" */ './figure.vue');

const setP1EventsIdentity = ({ p1events, brandKey, encryptedId }) => {
  if (!p1events || !brandKey || !encryptedId) return;
  p1events('setIdentity', `omeda.${brandKey}.customer*${encryptedId}~encrypted`);
};

export default (Browser) => {
  const { EventBus } = Browser;
  EventBus.$on('identity-x-logout', () => {
    if (window.p1events) window.p1events('setIdentity', null);
  });
  EventBus.$on('omeda-identity-x-authenticated', ({ brandKey, encryptedId }) => {
    setP1EventsIdentity({ p1events: window.p1events, brandKey, encryptedId });
  });
  EventBus.$on('omeda-identity-x-rapid-identify-response', ({ brandKey, encryptedId }) => {
    setP1EventsIdentity({ p1events: window.p1events, brandKey, encryptedId });
  });

  DefaultTheme(Browser);
  FII(Browser);
  GTM(Browser);
  GAM(Browser);
  Leaders(Browser);
  RevealAd(Browser);
  Search(Browser);
  Inquiry(Browser);
  P1Events(Browser);
  SocialSharing(Browser);
  PhotoSwipe(Browser);
  ContactUs(Browser);
  OmedaIdentityX(Browser);
  Browser.register('SharedAutoScroll', AutoScroll);
  Browser.register('SharedMenuToggleButton', MenuToggleButton);
  Browser.register('WufooForm', WufooForm);
  Browser.register('SurveyGizmoForm', SurveyGizmoForm);
  Browser.register('Figure', Figure);
};
